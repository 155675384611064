import React from 'react';
import {
    Dialog,
    DialogContent,
    Button,
    Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        width: '400px',
        maxWidth: '90vw',
        padding: theme.spacing(2),
        borderRadius: '10px',
        textAlign: 'center'
    },
    '& *': {
        fontFamily: 'IRANSans !important',
    }
}));

const CopyButton = styled(Button)({
    fontWeight: 'bold',
    color: '#1087FF',
    border: '1px solid #1087FF',
    borderRadius: '10px',
    padding: '6px 24px',
    minWidth: '120px',
    '&:hover': {
        backgroundColor: 'rgba(16, 135, 255, 0.04)',
        border: '1px solid #1087FF',
    }
});

const ConfirmButton = styled(Button)({
    fontWeight: 'bold',
    backgroundColor: '#1087FF',
    color: 'white',
    borderRadius: '10px',
    padding: '10px 20px',
    minWidth: '150px',
    '&:hover': {
        backgroundColor: '#0b6cd4',
    }
});

const OrgCodeConfirmationModal = ({open, onClose, code, orgName}) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(code);
    };

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            aria-labelledby="org-code-dialog-title"
            dir="rtl"
        >
            <DialogContent>
                <Typography variant="body1" component="div" sx={{
                    mb: 1,
                }}>
                    سازمان
                </Typography>

                <Typography variant="h5" component="div" sx={{
                    mb: 1,
                    fontWeight: 'bold'
                }}>
                    {orgName}
                </Typography>

                <Typography variant="body1" component="div" sx={{
                    mb: 8,

                }}>
                    به سامانه ایران‌تدارکات اضافه شد.
                </Typography>

                <Typography variant="body1" component="div" sx={{mb: 1}}>
                    کد سازمان
                </Typography>

                <Typography variant="h4" component="div" sx={{
                    mb: 2,
                    fontWeight: 'bold',
                }}>
                    {code}
                </Typography>

                <CopyButton
                    variant="outlined"
                    // startIcon={<ContentCopyIcon />}
                    onClick={handleCopy}
                    sx={{mb: 3}}
                >
                    کپی
                </CopyButton>

                <Typography variant="body2" component="div" sx={{
                    mb: 4,
                    color: 'text.secondary'
                }}>
                    از این کد می‌توانید برای ثبت نام کاربران این سازمان استفاده کنید.
                </Typography>

                <ConfirmButton
                    variant="contained"
                    onClick={onClose}
                >
                    تایید
                </ConfirmButton>
            </DialogContent>
        </StyledDialog>
    );
};

export default OrgCodeConfirmationModal;