import EntityListSection from "./EntityListSection";
import {
    createOrganization,
    deactivateOrganization,
    editOrgByCode,
    getAllPaginatedOrganizations
} from "../../APIs/organization";
import {MdModeEdit} from "@react-icons/all-files/md/MdModeEdit";
import {MdDoNotDisturb} from "@react-icons/all-files/md/MdDoNotDisturb";
import convertDateToShamsi from "../../utils/functions";

const OrganizationsSection = () => {
    const generateTableConfig = (handleEditClick, handleDisableClick) => ({
        headers: [
            {value: "نام سازمان", sort: true},
            {value: "کد سازمان"},
            {value: "تاریخ عضویت", sort: true},
            {value: "تعداد مسئولین تدارکات", sort: true},
            {value: "وضعیت فعالیت"},
            {value: "ویرایش", width: "50px"},
            {value: "غیرفعال کردن", width: "100px"}
        ],
        transformRow: (org) => [
            {value: org.name},
            {value: org.code},
            {value: convertDateToShamsi(org.creation_date)},
            {value: org.user_count},
            {value: org.is_active ? "فعال" : "غیرفعال"},
            {
                value: (
                    <MdModeEdit
                        style={{cursor: "pointer"}}
                        onClick={() => handleEditClick(org)}
                    />
                ),
                width: "50px"
            },
            {
                value: (
                    <MdDoNotDisturb
                        style={{
                            cursor: org.is_active ? "pointer" : "not-allowed",
                            color: org.is_active ? "red" : "gray",
                            opacity: org.is_active ? 1 : 0.5,
                        }}
                        onClick={org.is_active ? () => handleDisableClick(org) : undefined}
                    />
                ),
                width: "100px"
            }
        ]
    });

    return (
        <EntityListSection
            entityType="orgs"
            sortDict={{0: "name", 2: "creation_date", 3: "user_count"}}
            fetchEntities={getAllPaginatedOrganizations}
            editEntity={editOrgByCode}
            headerText="فهرست سازمان‌های ثبت شده"
            newButtonText="سازمان جدید"
            generateTableConfig={generateTableConfig}
            editModalTitle={"ویرایش مشخصات سازمان"}
            editModalFields={[
                {name: 'name', label: 'نام', type: 'text'},
            ]}
            newModalTitle={"اضافه کردن سازمان جدید"}
            newModalFields={[
                {name: 'name', label: 'نام سازمان (فارسی)', type: 'text'},
                {name: 'english_name', label: 'نام سازمان (انگلیسی)', type: 'text'},
            ]}
            newEntity={createOrganization}
            handleDisable={deactivateOrganization}
        />
    );
};

export default OrganizationsSection;